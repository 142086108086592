<template>
    <a-drawer
            title="关注列表"
            placement="right"
            :closable="false"
            :visible="visible"
            :width="500"
            :bodyStyle="{'padding':0}"
            @close="onBBSWatchlistModalClose()"
    >
        <div class="huiwen-container huiwen-left">
            <template v-if="watchlist.length > 0">
                <div v-for="(item,index) in watchlist" class="watchlist-box" @click="toStaffInfoDetail(index)">
                    <img class="huiwen-left huiwen-pointer" :src="item.avatar"></img>
                    <div class="huiwen-left watchlist-content">
                        <div class="huiwen-container huiwen-left">
                            <div class="huiwen-left staff-name">
                                <div class="huiwen-left huiwen-pointer">
                                    {{item.staff_name}}
                                </div>
                            </div>
                            <div class="huiwen-left tip">
                                被 {{item.cnt_followed}} 人关注
                            </div>
                        </div>
                    </div>
                    <div class="huiwen-left watchlist-btn-box huiwen-layout-flex-tb-center" style="flex-direction: row-reverse;">
                        <div @click.stop="doFollow(item.staff_id)" class="guanzhu-btn huiwen-pointer">
                            已关注
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="huiwen-container huiwen-left">
                    <a-empty  style="padding: 50px 0"/>
                </div>
            </template>
            <div class="huiwen-container huiwen-left huiwen-margin-top-10 huiwen-margin-bottom-10">
                <cmy-page :page_now="watchlist_pagination.page" :bo_end="watchlist_pagination.end" @next="getWatchlist(watchlist_pagination.page + 1)" @previous="getWatchlist(watchlist_pagination.page - 1)"></cmy-page>
            </div>
        </div>
        <bbs-personal-area :staff_id="staff_id_select" :mode="personal_area_mode" @close="closeBBSPersonalArea()" @changMode="changeMode" @resetFollowCallback="resetFollowCallback()"></bbs-personal-area>
    </a-drawer>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { doQuery, doAction } from "@/api/core"
    import CmyPage from '@/components/cmy-page/cmy-page.vue'
    import BbsPersonalArea from '@/components/bbs-personal-area/bbs-personal-area.vue'

    export default {
        name: "bbs-watchlist",
        components: {
            CmyPage,
            BbsPersonalArea
        },
        computed: {
            ...mapGetters([
                'staffId',
            ])
        },
        data() {
            return {
                visible: false,

                watchlist: [],
                watchlist_pagination: {
                    page: 1,
                    limit: 10,
                    end: false
                },

                staff_id_select_index: undefined,
                staff_id_select: undefined,
                personal_area_mode: undefined,
            }
        },
        props: {
            open: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            'open': function (newVal,oldVal) {
                if(newVal) {
                    this.getWatchlist()
                        .then(() => {
                            this.visible = true
                        })
                }else {
                    this.visible = false
                }
            },
        },
        methods: {
            changeMode(mode) {
                const that = this
                if(mode == that.personal_area_mode) {
                    return
                }else {
                    that.personal_area_mode = mode
                }
            },
            toStaffInfoDetail(index) {
                const that = this
                that.staff_id_select_index = index
                that.openBBSPersonalArea(that.watchlist[index].staff_id)
            },
            resetFollowCallback(is_followed) {
                const that = this
                that.getWatchlist()
                that.$emit('resetFollowCallback')
            },

            openBBSPersonalArea(staff_id) {
                const that = this
                that.staff_id_select = staff_id
                that.personal_area_mode = 'post'
            },
            closeBBSPersonalArea() {
                const that = this
                that.staff_id_select_index = undefined,
                that.staff_id_select = undefined
                that.personal_area_mode = undefined
            },
            onBBSWatchlistModalClose() {
                const that = this
                that.$emit('close')
            },
            doFollow(staff_id) {
                const that = this
                that.$confirm({
                    centered: true,
                    title: '提示',
                    content: '是否想要取消关注?',
                    okText: '确定',
                    okType: 'primary',
                    cancelText: '取消',
                    onOk() {
                        doAction({
                            action_code: 'LNFR40',
                            param_str2: staff_id
                        }).then(res => {
                            if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                                that.$message.success('已取消关注')
                                that.$emit('resetFollowCallback')
                                that.getWatchlist()
                            }else {
                                that.$message.error(res.errmsg)
                            }
                        })
                    },
                    onCancel() {

                    }
                })
            },
            getWatchlist(page = 1) {
                const that = this
                return new Promise((resolve, reject) => {
                    doQuery({
                        query_code: 'LNFQ40',
                        page: page,
                        limit: that.watchlist_pagination.limit
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data) {
                            that.watchlist = res.data
                            that.watchlist_pagination.page = page
                            that.watchlist_pagination.end = res.data.length < that.watchlist_pagination.limit
                            resolve()
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .watchlist-box {
        width: 100%;
        float: left;
        padding: 15px;
        box-sizing: border-box;
        border-bottom: 1px solid #f6f6f6;
    }
    .watchlist-box img {
        width: 32px;
        height: 32px;
        border-radius: 32px;
    }
    .watchlist-box .watchlist-content {
        width: calc(100% - 100px);
        padding-left: 15px;
        box-sizing: border-box;
    }

    .watchlist-box .watchlist-btn-box {
        width: 68px;
        height: 32px;
    }

    .guanzhu-btn {
        height: 24px;
        padding: 0 10px;
        font-size: 12px;
        color: #494949;
        border: 1px solid #f6f6f6;
        text-align: center;
        line-height: 24px;
        border-radius: 24px;
    }

    .watchlist-box .watchlist-content .staff-name {
        float: left;
        width: 100%;
        height: 16px;
        line-height: 16px;
        color: #8799a3;
        font-size: 12px;
    }
    .watchlist-box .watchlist-content .tip {
        float: left;
        width: 100%;
        height: 16px;
        line-height: 16px;
        color: #8E8E93;
        font-size: 12px;
    }

</style>
