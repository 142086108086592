<template>
    <a-drawer
            title="社区消息"
            placement="right"
            :closable="false"
            :visible="visible"
            :width="500"
            :bodyStyle="{'padding':0}"
            @close="onBBSMessageModalClose()"
    >
        <div class="huiwen-container huiwen-left">
            <template v-if="bbs_message.length > 0">
                <div v-for="(item, index) in bbs_message" class="bbsmsg-box huiwen-pointer" @click="toComminicate(item.ref_post_id)">
                    <div class="huiwen-container huiwen-left">
                        <div class="huiwen-left" style="width: 70%;">
                            <div class="avatar-box huiwen-layout-flex-center">
                                <img :src="item.avatar"></img>
                            </div>
                            <div class="sender-name">
                                {{item.comment_staff_name}}
                            </div>
                            <div v-if="item.ref_comment_id && item.ref_post_id" class="sender-time">
                                回复了你的[回复]
                            </div>
                            <div v-if="!item.ref_comment_id && item.ref_post_id" class="sender-time">
                                回复了你的[帖子]
                            </div>
                        </div>
                        <div  class="huiwen-left huiwen-layout-flex-tb-center" style="height: 32px;width: 30%;flex-direction: row-reverse;">
                            <div style="height: 32px;margin-left: 10px;" class="huiwen-layout-flex-center huiwen-pointer" @click.stop="doPraise(index)">
                                <span :class="item.is_praised == 1 ?'iconfont icon-dianzan huiwen-text-orange' : 'iconfont icon-dianzan huiwen-text-gary-light'" style="font-size: 14px;"></span>
                                <span :class="item.is_praised == 1 ? 'huiwen-text-orange' : 'huiwen-text-gary-light'" style="font-size: 12px;margin-left: 3px;">{{item.cnt_praise}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="huiwen-container huiwen-left comment-content">
                        {{item.content}}
                    </div>
                    <div v-if="item.ref_comment_id" class="huiwen-container huiwen-left comment-ref huiwen-over-one">
                        {{'我的回复：' + item.ref_comment_content}}
                        <div v-if="item.ref_post_id" class="huiwen-container huiwen-left comment-ref-2 huiwen-over-one" style="margin-top: 10px;">
                            {{'原帖 | ' + item.ref_post_title}}
                        </div>
                    </div>
                    <div v-else-if="item.ref_post_id" class="huiwen-container huiwen-left comment-ref huiwen-over-one">
                        {{'原帖 | ' + item.ref_post_title}}
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="huiwen-container huiwen-left">
                    <a-empty  style="padding: 50px 0"/>
                </div>
            </template>
            <div class="huiwen-container huiwen-left huiwen-margin-top-10 huiwen-margin-bottom-10">
                <cmy-page :page_now="bbs_pagination.page" :bo_end="bbs_pagination.end" @next="queryBBSMessage(bbs_pagination.page + 1)" @previous="queryBBSMessage(bbs_pagination.page - 1)"></cmy-page>
            </div>
        </div>
    </a-drawer>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { doQuery, doAction } from "@/api/core"
    import CmyPage from '@/components/cmy-page/cmy-page.vue'

    export default {
        name: "bbs-watchlist",
        components: {
            CmyPage,
        },
        computed: {
            ...mapGetters([
                'staffId',
            ])
        },
        data() {
            return {
                visible: false,

                bbs_message: [],
                bbs_pagination: {
                    page: 1,
                    limit: 10,
                    end: true
                }
            }
        },
        props: {
            open: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            'open': function (newVal,oldVal) {
                if(newVal) {
                    this.queryBBSMessage()
                        .then(() => {
                            this.visible = true
                        })
                }else {
                    this.visible = false
                }
            },
        },
        methods: {
            doPraise(comment_index = null) {
                const that = this
                doAction({
                    action_code: 'LNFR30',
                    param_str5: JSON.stringify({
                        post_id: that.bbs_message[comment_index].ref_post_id,
                        comment_id: that.bbs_message[comment_index].comment_id,
                        praise_staff_id: that.staffId,
                    })
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                        var bo_praise = that.bbs_message[comment_index].is_praised == 0
                        var cnt_praise = that.bbs_message[comment_index].cnt_praise
                        that.$message.success(!bo_praise ? '已取消点赞' : '点赞成功')
                        that.$set(that.bbs_message[comment_index],'is_praised', bo_praise ? 1 : 0)
                        that.$set(that.bbs_message[comment_index],'cnt_praise', bo_praise ? cnt_praise + 1 : cnt_praise - 1)

                        doAction({
                            action_code: 'LNFR80',
                        }).then(res => {
                            if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {}else {}
                        })
                    }else {
                        that.$message.error(res.errmsg)
                    }
                })
            },
            toComminicate(post_id) {
                const that = this
                that.$emit('close')
                if(that.$route.name == 'StudentPostDetail') {
                    that.$router.replace('/student/postDetail?post_id=' + post_id)
                }else {
                    that.$router.push('/student/postDetail?post_id=' + post_id)
                }
            },
            onBBSMessageModalClose() {
                const that = this
                that.$emit('close')
            },
            queryBBSMessage(page = 1) {
                const that = this
                return new Promise((resolve, reject) => {
                    doQuery({
                        query_code: 'LNM03',
                        page: page,
                        limit: that.bbs_pagination.limit
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data) {
                            that.bbs_message = res.data
                            that.bbs_pagination.page = page
                            that.bbs_pagination.end = res.data.length < that.bbs_pagination.limit
                            resolve()
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .bbsmsg-box {
        float: left;
        width: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        border-bottom: 1px solid #f6f6f6;
        background-color: #FFFFFF;
    }

    .bbsmsg-box .avatar-box {
        width: 32px;
        height: 32px;
        float: left;
    }
    .bbsmsg-box .avatar-box img {
        width: 32px;
        height: 32px;
        border-radius: 32px;
    }

    .bbsmsg-box .sender-name {
        float: left;
        width: calc(100% - 32px);
        height: 16px;
        line-height: 16px;
        color: #8799a3;
        font-size: 14px;
        padding-left: 5px;
        box-sizing: border-box;
    }
    .bbsmsg-box .sender-time {
        float: left;
        width: calc(100% - 32px);
        height: 16px;
        line-height: 16px;
        color: #8E8E93;
        font-size: 12px;
        padding-left: 5px;
        box-sizing: border-box;
    }

    .bbsmsg-box .comment-content {
        padding: 10px 0;
        line-height: 18px;
        font-size: 15px;
        color: #494949;
        font-weight: bold;
    }

    .bbsmsg-box .comment-ref {
        box-sizing: border-box;
        padding: 10px;
        line-height: 18px;
        font-size: 13px;
        color: gray;
        border-radius: 3px;
        background-color: #F8F8F8;
    }
    .bbsmsg-box .comment-ref-2 {
        box-sizing: border-box;
        padding: 10px;
        line-height: 18px;
        font-size: 13px;
        color: gray;
        border-radius: 3px;
        background-color: #ffffff;
    }
</style>
